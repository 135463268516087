<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            {{ $t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_iabm.circular_memo_no') }}</b-th>
                                <b-td style="width:30%">{{ (contentManagement.circular_memo_no) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? contentManagement.fiscal_year_bn : contentManagement.fiscal_year }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('org_pro.organization') }}</b-th>
                                <b-td style="width:30%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ ($i18n.locale=='bn') ? contentManagement.org_bn : contentManagement.org }}</b-td>
                                <b-th style="width:20%">{{ $t('org_pro.office_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? contentManagement.office_type_bn : contentManagement.office_type }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('org_pro.office_namel') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? contentManagement.office_bn : contentManagement.office }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.training_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? contentManagement.training_type_bn : contentManagement.training_type }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.training_category') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? contentManagement.training_category_bn : contentManagement.training_category }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.training_title') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? contentManagement.training_title_bn : contentManagement.training_title }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_management.content_name') }}</b-th>
                                <b-td style="width:30%" colspan="3">{{ ($i18n.locale=='bn') ? contentManagement.content_name_bn : contentManagement.content_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.remarks') }}</b-th>
                                <b-td style="width:30%" colspan="3">{{ ($i18n.locale=='bn') ? contentManagement.remarks_bn : contentManagement.remarks }}</b-td>
                            </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import ExportPdf from './export-pdf_details'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    components: {
      ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
        this.contentManagement = this.item
    },
    data () {
        return {
            contentManagement: [],
            slOffset: 1,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    methods: {
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Content Management Details' : 'কন্টেন্ট ম্যানেজমেন্ট বিবরণ'
            ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.contentManagement, this)
        }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
