<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circular_memo_no"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="circular_memo_no"
                            v-model="formData.circular_memo_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            readonly
                            plain
                            v-model="formData.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Organization" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            readonly
                            plain
                            v-model="formData.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_type_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.office_type')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            readonly
                            plain
                            v-model="formData.office_type_id"
                            :options="officeTypeList"
                            id="office_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Office" vid="office_id" rules="">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.office')}}
                        </template>
                        <b-form-select
                            readonly
                            plain
                            v-model="formData.office_id"
                            :options="officeList"
                            id="office_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          readonly
                          plain
                          v-model="formData.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_category_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          readonly
                          plain
                          v-model="formData.training_category_id"
                          :options="trainingCategoryList"
                          id="training_category_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_title_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          readonly
                          plain
                          v-model="formData.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Content Name (En)" vid="content_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="content_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{ $t('elearning_management.content_name_en') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="content_name"
                          v-model="formData.content_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Content Name (Bn)" vid="content_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="content_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('elearning_management.content_name_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="content_name_bn"
                          v-model="formData.content_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Upload (Attachement)" vid="upload_attachement">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="upload_attachement"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{ $t('elearning_management.upload_attachement') }}
                        </template>
                        <b-form-file
                            id="upload_attachement"
                            @change="onChangeFile"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="remarks"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.remarks_en')}}
                        </template>
                        <b-form-textarea
                            rows="2"
                            id="remarks"
                            v-model="formData.remarks"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="remarks_bn"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.remarks_bn')}}
                        </template>
                        <b-form-textarea
                            rows="2"
                            id="remarks_bn"
                            v-model="formData.remarks_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { contentManagementStore, contentManagementUpdate, circularPublication } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        content_name: '',
        content_name_bn: '',
        upload: '',
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      officeTypeList: [],
      officeList: [],
      trainingCategoryList: [],
      trainingTitleList: []
    }
  },
  computed: {
    orgList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        } else {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    async getCircularMemoNo (getCircularMemoNo) {
        const serchData = {
          org_id: this.$store.state.dataFilters.orgId
        }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication + '/' + getCircularMemoNo, serchData)
      if (result.success) {
        const trData = result.data
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.office_id = trData.office_id
        this.formData.office_type_id = trData.office_type_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_category_id = trData.training_category_id
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_attachement = e.target.files[0]
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.formData).map(key => {
        formData.append(key, this.formData[key])
      })
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${contentManagementUpdate}/${this.id}`, formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, contentManagementStore, formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
