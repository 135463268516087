<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_management.content')}}   {{ $t('globalTrans.search') }}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_management.content')}}   {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(training_start_date)="data">
                                          <span class="capitalize">{{ data.item.training_start_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(training_end_date)="data">
                                          <span class="capitalize">{{ data.item.training_end_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(upload_attachement)="data">
                                            <a  v-if="data.item.upload_attachement" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/content-management/' + data.item.upload_attachement" title="Attachment" class="mr-3" download><i class="ri-download-cloud-fill"></i></a>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                                            <span v-if="data.item.status === 1">
                                                <a href="javascript:" class="btn_table_action table_action_status" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            </span>
                                            <span v-if="data.item.status === 2">
                                                <a href="javascript:" class="btn_table_action table_action_toggle" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                                            </span>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_management.content_management_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <Details :item="item" :key="id" ref="details"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { contentManagementList, contentManagementToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
            search: {
                org_id: 0,
                fiscal_year_id: 0
            },
            id: 0,
            item: ''
        }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
            if (this.$store.state.Auth.authUser.org_id === 12) {
                return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
            } else {
                return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            }
        },
        trainerEvaluationList: function () {
          return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_management.content_management') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_management.content_management') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
                { label: this.$t('elearning_config.organization'), class: 'text-center' },
                { label: this.$t('elearning_config.training_title'), class: 'text-center' },
                { label: this.$t('elearning_management.content_name'), class: 'text-center' },
                { label: this.$t('elearning_management.attachement'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year_bn' },
                { key: 'org_bn' },
                { key: 'training_title_bn' },
                { key: 'content_name_bn' },
                { key: 'upload_attachement' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'org' },
                { key: 'training_title' },
                { key: 'content_name' },
                { key: 'upload_attachement' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, contentManagementToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, contentManagementList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office = officeObj.text_en
              officeData.office_bn = officeObj.text_bn
            } else {
              officeData.office = ''
              officeData.office_bn = ''
            }

            const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
            const OfficeTypeData = {}
            if (typeof OfficeTypeListObj !== 'undefined') {
                OfficeTypeData.office_type = OfficeTypeListObj.text_en
                OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
            } else {
                OfficeTypeData.office_type = ''
                OfficeTypeData.office_type_bn = ''
            }

            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            //   fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
            const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
            const trainingTitleData = {}
            if (typeof trainingTitleObj !== 'undefined') {
              trainingTitleData.training_title = trainingTitleObj.text_en
              trainingTitleData.training_title_bn = trainingTitleObj.text_bn
            } else {
              trainingTitleData.training_title = ''
              trainingTitleData.training_title_bn = ''
            }

            const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
            const trainingTypeData = {}
            if (typeof trainingTypeObj !== 'undefined') {
                trainingTypeData.training_type = trainingTypeObj.text_en
                trainingTypeData.training_type_bn = trainingTypeObj.text_bn
            } else {
                trainingTypeData.training_type = ''
                trainingTypeData.training_type_bn = ''
            }

            const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
            const trainingCategoryData = {}
            if (typeof trainingCategoryObj !== 'undefined') {
                trainingCategoryData.training_category = trainingCategoryObj.text_en
                trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
            } else {
                trainingCategoryData.training_category = ''
                trainingCategoryData.training_category_bn = ''
            }

            return Object.assign({}, item, orgData, officeData, OfficeTypeData, fiscalYearData, trainingTitleData, trainingTypeData, trainingCategoryData)
          })
          return listData
        },
        pdfExportDetails () {
            this.$refs.details.pdfExport()
        }
    }
}
</script>
